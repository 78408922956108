
import {
  SET_CLASSRESERVATIONPENALTY,
  CREATE_CLASSRESERVATIONPENALTY,
  CLASSRESERVATIONPENALTYS_RECEIVED,
  SET_PROPERTY_CLASSRESERVATIONPENALTY,
} from "../types/ClassReservationPenaltys";

const schema = {

}

const ClassReservationPenaltysReducer = (state, { type, payload }) => {
  switch (type) {
    case CLASSRESERVATIONPENALTYS_RECEIVED:
      return { ...state, class_reservation_penalties: payload };
    case SET_CLASSRESERVATIONPENALTY:
      return { ...state, class_reservation_penalty: payload };
    case CREATE_CLASSRESERVATIONPENALTY:
      return { ...state, class_reservation_penalty: schema };
    case SET_PROPERTY_CLASSRESERVATIONPENALTY: {
      const { key, value } = payload;
      const class_reservation_penalty = { ...state.class_reservation_penalty };
      class_reservation_penalty[key] = value;
      return { ...state, class_reservation_penalty };
    }
    default:
      return { ...state};
  }
};

export default ClassReservationPenaltysReducer;
