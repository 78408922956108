import React, { useContext } from "react";
import { getValue } from "../../utils";
import ReactSwitch from "react-switch";
import { getLocalMomentObject } from "../../utils/dates";
import { AppConfigContext } from "../../context/AppConfigContext";
import { AvailableClassesContext } from "../../context/AvailableClassesContext";

const AvailableClassForm = ({
  handleCancel,
  handleCallback,
  editMany,
  items,
}) => {
  const {
    available_class,
    saveAvailableClass,
    setPropertyAvailableClass,
    saveManyAvailableClasses,
  } = useContext(AvailableClassesContext);
  const appconfig = useContext(AppConfigContext);

  const allow_class_guests = getValue(
    appconfig,
    "allow_class_guests",
    "boolean"
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (editMany) {
      const data = {
        start_date: available_class.start_date,
        allow_guest: available_class.allow_guest,
        expiration_date: available_class.expiration_date,
        items,
      };
      saveManyAvailableClasses(data, handleCallback);
    } else {
      saveAvailableClass(available_class, handleCallback);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Fecha de Inicio</label>
      <input
        type="date"
        className="form-control mb-3"
        value={getValue(available_class, "start_date", "date")}
        onChange={(e) =>
          setPropertyAvailableClass("start_date", e.target.value)
        }
      />
      <label>Fecha de Expiración</label>
      <input
        type="date"
        className="form-control mb-3"
        value={getValue(available_class, "expiration_date", "date")}
        onChange={(e) => {
          const updatedDate = getLocalMomentObject(e.target.value)
            .endOf("day")
            .format();
          setPropertyAvailableClass("expiration_date", updatedDate);
        }}
      />
      {allow_class_guests && (
        <div className="row align-items-center mb-3">
          <div className="col-6">
            <label>¿Permitir invitados?</label>
          </div>
          <div className="col-6 text-end">
            <ReactSwitch
              checked={getValue(available_class, "allow_guest", "boolean")}
              onChange={(checked) =>
                setPropertyAvailableClass("allow_guest", checked)
              }
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col">
          <button
            type="button"
            onClick={handleCancel}
            className="btn w-100 text-muted"
          >
            Cancelar
          </button>
        </div>
        <div className="col">
          <button type="submit" className="btn w-100 btn-primary">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default AvailableClassForm;
