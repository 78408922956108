import React, { useContext, useEffect, useState } from "react";
import { SingleClassContext } from "../../context/SingleClassContext";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import { LocationsContext } from "../../context/LocationsContext";
import { PackagesContext } from "../../context/PackageContext";
import { CoachesContext } from "../../context/CoachesContext";
import SelectInstructors from "../global/SelectInstructors";
import { TagsContext } from "../../context/TagsContext";
import DateTimePicker from "../common/DateTimePicker";
import SelectTags from "../global/SelectTags";
import ReactSwitch from "react-switch";
import { getValue } from "../../utils";
import moment from "moment";

const SingleClassForm = ({ handleCancel, handleCallback, single_class_id }) => {
  const [repeat, setRepeat] = useState(false);
  const [repeatDays, setRepeatDays] = useState([]);
  const [repeatInterval, setRepeatInterval] = useState(1);

  const {
    clase,
    addTag,
    removeTag,
    postClase,
    clearClase,
    createClase,
    addInstructor,
    getSingleClass,
    removeInstructor,
    setPropiedadClase,
  } = useContext(SingleClassContext);

  const { tags, getTags } = useContext(TagsContext);

  const { coaches, getCoaches } = useContext(CoachesContext);

  const { locations, getLocations } = useContext(LocationsContext);

  const { paquetes, getAllPaquetes } = useContext(PackagesContext);

  const { class_types, getClassTypes } = useContext(ClassTypeContext);

  useEffect(() => {
    getAllPaquetes();
    getCurrenClase();
    getClassTypes();
    getLocations();
    getCoaches();
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearClase();
    getCurrenClase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [single_class_id]);

  useEffect(() => {
    if (clase && clase !== null) {
      const classDay = parseInt(moment(clase.class_date).utc().day());
      if (!repeatDays.includes(classDay)) toggleRepeatDay(classDay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clase]);

  const getCurrenClase = () => {
    if (isNaN(single_class_id)) {
      createClase();
    } else {
      getSingleClass(single_class_id);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { ...clase };
    postClase(data, handleCallback);
    if (repeat) {
      const time = getValue(data, "class_date", "time");
      for (let i = 0; i < repeatInterval; i++) {
        const class_date = moment(getValue(data, "class_date", "date"))
          .add(i + 1, "weeks")
          .format(`YYYY-MM-DD ${time}`);
        postClase({ ...data, class_date });
      }
    }
  };

  const toggleRepeatDay = (dayIndex) => {
    const current = [...repeatDays];
    const index = current.findIndex((day) => day === dayIndex);
    if (index === -1) current.push(dayIndex);
    else current.splice(index, 1);
    setRepeatDays(current);
  };

  const renderLocations = () => {
    if (Array.isArray(locations)) {
      if (locations.length > 0 && clase.location_id === "") {
        setTimeout(() => {
          setPropiedadClase("location_id", locations[0].location_id);
        }, 100);
      }
      return locations.map((location) => (
        <option key={location.location_id} value={location.location_id}>
          {location.name}
        </option>
      ));
    }
  };

  const renderClassTypes = () => {
    if (Array.isArray(class_types)) {
      if (class_types.length > 0 && clase.class_type_id === "") {
        setTimeout(() => {
          setPropiedadClase("class_type_id", class_types[0].class_type_id);
        }, 100);
      }
      return class_types.map((class_type) => (
        <option key={class_type.class_type_id} value={class_type.class_type_id}>
          {class_type.name}
        </option>
      ));
    }
  };

  const renderPaquetes = () => {
    if (Array.isArray(paquetes)) {
      return [{ title: "No es evento especial", class_package_id: null }]
        .concat(paquetes)
        .map((paquete) => (
          <option
            key={paquete.class_package_id}
            value={paquete.class_package_id}
          >
            {paquete.title}
          </option>
        ));
    }
  };

  const renderRepeat = () => {
    if (repeat) {
      return (
        <div className="col-12 col-md-6">
          <div className="row align-items-center">
            <div className="col-4">
              <input
                type="number"
                value={repeatInterval}
                className="form-control"
                onChange={(e) => setRepeatInterval(e.target.value)}
              />
            </div>
            <div className="col-8">semanas</div>
          </div>
        </div>
      );
    }
  };

  const renderRepeatDays = () => {
    if (repeat) {
      return (
        <div className="row align-items-center mb-4">
          <div className="btn-group">
            {[1, 2, 3, 4, 5, 6, 7].map((dayIndex) => (
              <button
                key={dayIndex}
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleRepeatDay(dayIndex);
                }}
                className={`btn border btn-${
                  repeatDays.includes(dayIndex) ? "primary" : "light"
                }`}
              >
                {moment(dayIndex === 7 ? 0 : dayIndex, "d").format("dd")}
              </button>
            ))}
          </div>
        </div>
      );
    }
  };

  const renderForm = () => {
    if (clase && clase !== null) {
      const class_date = getValue(clase, "class_date", "datetime");
      return (
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col col-md-6">
              <label>Tipo de Clase</label>
              <select
                className="form-control"
                value={getValue(clase, "class_type_id")}
                onChange={(e) =>
                  setPropiedadClase("class_type_id", parseInt(e.target.value))
                }
              >
                {renderClassTypes()}
              </select>
            </div>
            <div className="col col-md-6">
              <label>Ubicación</label>
              <select
                className="form-control"
                value={getValue(clase, "location_id")}
                onChange={(e) => {
                  setPropiedadClase("location_id", parseInt(e.target.value));
                }}
              >
                {renderLocations()}
              </select>
            </div>
          </div>
          <label>Descripción</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(clase, "description")}
            onChange={(e) => setPropiedadClase("description", e.target.value)}
          />
          <DateTimePicker
            value={class_date}
            modifier={(value) => setPropiedadClase("class_date", value)}
          />
          <div className="row">
            <div className="col-6">
              <SelectInstructors
                instructors={coaches}
                addInstructor={addInstructor}
                removeInstructor={removeInstructor}
                selected={clase.class_instructors}
              />
            </div>
            <div className="col-6">
              <SelectTags
                tags={tags}
                addTag={addTag}
                removeTag={removeTag}
                selected={clase.tags}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col col-md-6">
              <label>Capacidad</label>
              <input
                type="number"
                min={0}
                className="form-control"
                value={getValue(clase, "capacity")}
                onChange={(e) => setPropiedadClase("capacity", e.target.value)}
              />
            </div>
            <div className="col col-md-6">
              <label>Evento Especial</label>
              <select
                className="form-control mb-3"
                value={getValue(clase, "class_package_id")}
                onChange={(e) =>
                  setPropiedadClase("class_package_id", e.target.value)
                }
              >
                {renderPaquetes()}
              </select>
            </div>
          </div>
          <div className="row align-items-center mb-3">
            <div className="col-12 col-md-6">
              <div className="row align-items-start">
                <div className="col-8">
                  <span>Repetir Clase</span>
                </div>
                <div className="col-4 text-end">
                  <ReactSwitch checked={repeat} onChange={setRepeat} />
                </div>
              </div>
            </div>
            {renderRepeat()}
          </div>
          {/* {renderRepeatDays()} */}
          <div className="row">
            <div className="col col-md-6 text-end">
              <button
                type="button"
                onClick={handleCancel}
                className="btn w-100 text-muted"
              >
                Cancelar
              </button>
            </div>
            <div className="col col-md-6">
              <button type="submit" className="btn w-100 btn-primary">
                Guardar
              </button>
            </div>
          </div>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default SingleClassForm;
