import React, { useContext } from "react";
// import DesignInputs from "../../components/ajustes/DesignInputs";
// import AppImagesInputs from "../../components/ajustes/AppImagesInputs";
import SingleClassesSettings from "../../components/ajustes/SingleClassesSettings";
import AppearanceSection from "../../components/ajustes/AppearanceSection";
import MarketingSettings from "../../components/ajustes/MarketingSettings";
import AdvancedSettings from "../../components/ajustes/AdvancedSettings";
import ContactSection from "../../components/ajustes/ContactSection";
import { AppConfigContext } from "../../context/AppConfigContext";
// import LegalSection from "../../components/ajustes/LegalSection";
import HomeSection from "../../components/ajustes/HomeSection";
import useCrudAppConfig from "../../hooks/useCrudAppConfig";
import PanelTitle from "../../components/global/PanelTitle";
import { UserContext } from "../../context/UserContext";
import "../../css/settings.css";
import { Link, Router, useLocation } from "@reach/router";
import FeaturesSettings from "../../components/ajustes/FeaturesSettings";

const tabs = [
  {
    key: "home",
    label: "Home",
  },
  {
    key: "appearance",
    label: "Aspecto",
  },
  {
    key: "contact",
    label: "Contacto",
  },
  {
    key: "classes",
    label: "Clases",
  },
  {
    key: "marketing",
    label: "Marketing",
  },
  // {
  //   key: "legal",
  //   label: "Legal",
  // },
  {
    key: "advanced",
    label: "Avanzado",
    role: "super_admin",
  },
  {
    key: "features",
    label: "Funcionalidades",
    role: "super_admin",
  },
];

const AdminAjustes = () => {
  const { loading } = useContext(AppConfigContext);
  const location = useLocation();

  const { user } = useContext(UserContext);
  const { saveAppConfig } = useCrudAppConfig();

  const handleSaveConfig = async () => {
    await saveAppConfig();
  };

  const renderNav = () => {
    return (
      <ul className="nav nav-tabs admin-settings-nav">
        {tabs
          .filter((tab) => tab.role === user.role || !tab.role)
          .map((tab, index) => (
            <li key={tab.key} className="nav-item">
              <Link
                className={`nav-link 
                    ${
                      location.pathname.includes(tab.key)
                        ? "active text-primary"
                        : "text-dark"
                    }
                  `}
                aria-current="page"
                to={`/myadmin/ajustes/${tab.key}`}
              >
                {tab.label}
              </Link>
            </li>
          ))}
      </ul>
    );
  };

  return (
    <div className="container-fluid position-relative overflow-hidden">
      <PanelTitle
        title="Ajustes"
        buttonComponent={
          <button
            onClick={handleSaveConfig}
            className="btn btn-primary"
            disabled={loading}
          >
            <i className="fa fa-save me-1" />{" "}
            {loading ? (
              <div className="spinner-border spinner-border-sm" />
            ) : (
              "Guardar"
            )}
          </button>
        }
      />

      <div
        className="card position-relative overflow-hidden"
        style={{
          cursor: "unset",
          height: "calc(100% - 120px)",
        }}
      >
        {renderNav()}

        <div
          className="row w-100 m-auto mt-3 overflow-auto mb-3 "
          style={{
            flex: 1,
            // maxHeight: 'calc(100% - 60px)'
          }}
        >
          <Router className="px-0">
            {/* <LegalSection path="/legal" /> */}
            <ContactSection path="/contact" />
            <HomeSection path="/home" default />
            <MarketingSettings path="/marketing" />
            <AppearanceSection path="/appearance" />
            <SingleClassesSettings path="/classes" />
            {user.role === "super_admin" && (
              <>
                <AdvancedSettings path="/advanced" />
                <FeaturesSettings path="/features" />
              </>
            )}
          </Router>
        </div>
      </div>
    </div>
  );
};

export default AdminAjustes;
