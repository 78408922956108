import React, { useContext } from "react";
import { getValue } from "../../utils";
import SingleFeatureSetting from "./SingleFeatureSetting";
import { AppConfigContext } from "../../context/AppConfigContext";

const FeaturesSettings = () => {
  const { videos_enabled, single_class_enabled } = useContext(AppConfigContext);

  return (
    <div className="col-12 px-0">
      <h2 className="h4">Funcionalidades</h2>
      <p>Configura a detalle los permisos de este estudio.</p>
      <SingleFeatureSetting
        title="Suscripciones / Pagos Recurrentes"
        description="Cobra automáticamente a tus usuarios cada mes, trimestre, etc.
            directamente a su método de pago."
        appConfigKey="subscriptions_enabled"
      />
      <SingleFeatureSetting
        title="Códigos de Descuento"
        description={`Ofrece promociones especiales a tus usuarios como descuento en $, %
            ${
              getValue(
                { single_class_enabled },
                "single_class_enabled",
                "boolean"
              ) && "o cantidad de clases presenciales."
            }`}
        appConfigKey="discounts_enabled"
      />
      <SingleFeatureSetting
        title="Clases Online"
        description="Sube videos y vende membresías de acceso a clases on-demand."
        appConfigKey="videos_enabled"
      />
      {getValue({ videos_enabled }, "videos_enabled", "boolean") && (
        <div>
          <SingleFeatureSetting
            title="Calificaciones de Clases Online"
            description="Tus usuarios pueden asignar una calificación del 1 al 5 para su
                clase online y otra calificación para su coach con un comentario
                opcional."
            appConfigKey="videos_rating_enabled"
          />
        </div>
      )}

      <SingleFeatureSetting
        title="Testimonios"
        description="Agrega testimonios de tus usuarios para mostrarlos en tu página de inicio"
        appConfigKey="testimonials_enabled"
      />

      <SingleFeatureSetting
        title="Páginas"
        description="Agrega páginas con información personalizada y módulos visuales predefinidos."
        appConfigKey="pages_enabled"
      />

      <SingleFeatureSetting
        title="Clases Presenciales"
        description="Sube tus horarios y vende paquetes de clases presenciales."
        appConfigKey="single_class_enabled"
      />

      {getValue(
        { single_class_enabled },
        "single_class_enabled",
        "boolean"
      ) && (
        <div>
          <SingleFeatureSetting
            title="Invitados"
            description="Tus usuarios pueden compartir sus clases y resvervar más de 1
                lugar por clase indicando el nombre del invitado."
            appConfigKey="allow_class_guests"
          />
          <SingleFeatureSetting
            title="Tipos de Clases Restringidos"
            description="Ofrece paquetes de clases que solo permitan reservar en ciertos
                tipos de clases. Ejemplo: paquete de yoga y paquete de pilates."
            appConfigKey="allow_restricted_class_types"
          />
          <SingleFeatureSetting
            title="Mapa de Lugares"
            description="Configura el layout de tu estudio y permite que tus usuarios
                reserven en un lugar específico."
            appConfigKey="spotmap_enabled"
          />
          <SingleFeatureSetting
            title="Boleto con Código QR"
            description="Toma asistencia más fácil y rápido con un boleto QR para tus
                usuarios."
            appConfigKey="qrticket_enabled"
          />
          <SingleFeatureSetting
            title="Lista de Espera"
            description="Permite a tus usuarios anotarse para recibir un mensaje cuando
                se desocupa un lugar en una clase llena."
            appConfigKey="waitlist_enabled"
          />
          <SingleFeatureSetting
            title="Calificaciones de Clases Presenciales"
            description="Tus usuarios pueden asignar una calificación del 1 al 5 para su
                clase y otra calificación para su coach con un comentario
                opcional."
            appConfigKey="reservation_rating_enabled"
          />
          <SingleFeatureSetting
            title="Lederboard"
            description="Muestra una tabla de posiciones donde el primer lugar es el cliente con la mayor cantidad de asistencias a clases."
            appConfigKey="leaderboard_enabled"
          />
        </div>
      )}
    </div>
  );
};

export default FeaturesSettings;
