import React from "react";
import { Link } from "@reach/router";
import { getFriendlyLocalDateTimeString } from "../../utils/dates";

const PenaltyRow = ({ penalty, handleEdit, handleDelete, displayCustomer }) => {
  const renderName = () => {
    const { name, last_name } = penalty.customer;
    return `${name !== null ? name : ""} ${
      last_name !== null ? last_name : ""
    }`;
  };

  return (
    <tr className="border-bottom">
      <td>{penalty.class_reservation_penalty_id}</td>
      {displayCustomer && (
        <td>
          <Link to={`/myadmin/customer/${penalty.customer_id}`}>
            <i className="fa fa-eye me-2"></i>
            {renderName()}
          </Link>
        </td>
      )}
      <td>{getFriendlyLocalDateTimeString(penalty.start_date)}</td>
      <td>{getFriendlyLocalDateTimeString(penalty.end_date)}</td>
      <td>
        <Link
          to={`/myadmin/asistentes/${penalty.class_reservation?.single_class_id}`}
        >
          <i className="fa fa-eye me-1" />{" "}
          {getFriendlyLocalDateTimeString(
            penalty.class_reservation?.single_class?.class_date
          )}
        </Link>
      </td>
      <td>
        <button
          className="btn btn-sm btn-light border border-1 dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          type="button"
        />
        <ul className="dropdown-menu py-0">
          <li>
            <button
              className="dropdown-item px-2 "
              onClick={() => handleEdit(penalty)}
            >
              <i
                className="fas fa-edit text-primary me-2"
                style={{ width: "20px", height: "20px" }}
              />
              Editar
            </button>
          </li>
          <li>
            <button
              className="dropdown-item px-2 text-danger border-top"
              onClick={() => handleDelete(penalty)}
            >
              <i
                className="fas fa-trash me-2"
                style={{ width: "20px", height: "20px" }}
              />
              Eliminar
            </button>
          </li>
        </ul>
      </td>
    </tr>
  );
};

export default PenaltyRow;
