import React, { useContext } from "react";
import { ClassReservationPenaltysContext } from "../../context/ClassReservationPenaltysContext";
import PenaltyRow from "./PenaltyRow";
import { ModalContext } from "../../context/ModalContext";
import PenaltyForm from "./PenaltyForm";
import { getFriendlyLocalDateTimeString } from "../../utils/dates";
import ConfirmDelete from "../common/ConfirmDelete";

const PenaltiesTable = ({ displayCustomer, handleCallback }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);

  const {
    class_reservation_penalties,
    setClassReservationPenalty,
    deleteClassReservationPenalty,
  } = useContext(ClassReservationPenaltysContext);

  const handleCancel = () => {
    clearModal();
    setClassReservationPenalty(null);
  };

  const handleEdit = (penalty) => {
    setClassReservationPenalty(penalty);
    modalComponent(
      "Editar Penalización",
      <PenaltyForm
        handleCancel={handleCancel}
        handleCallback={handleCallback}
      />
    );
  };

  const handleDelete = (penalty) => {
    modalComponent(
      "Eliminar Penalización",
      <ConfirmDelete
        message={`¿Estás segura que deseas eliminar la penalización del cliente del ${getFriendlyLocalDateTimeString(
          penalty.start_date
        )} al ${getFriendlyLocalDateTimeString(penalty.end_date)}?`}
        handleDelete={() =>
          deleteClassReservationPenalty(
            penalty.class_reservation_penalty_id,
            handleCallback
          )
        }
        handleCancel={handleCancel}
      />
    );
  };



  const renderContent = () => {
    if (!Array.isArray(class_reservation_penalties)) {
      return <div className="spinner-border spinner-border-sm" />;
    }
    if (class_reservation_penalties.length === 0) {
      return <p>No hay penalizaciones para mostrar.</p>;
    }
    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr className="bg-light border bold">
              <th>#ID</th>
              {displayCustomer && <td>Cliente</td>}
              <th>Inicio</th>
              <th>Fin</th>
              <th>Ultima Clase</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {class_reservation_penalties.map((penalty) => (
              <PenaltyRow
                penalty={penalty}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleCallback={handleCallback}
                displayCustomer={displayCustomer}
                key={penalty.class_reservation_penalty_id}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return <div>{renderContent()}</div>;
};

export default PenaltiesTable;
