import React, { useContext } from "react";
import { SingleClassContext } from "../../context/SingleClassContext";
import { ModalContext } from "../../context/ModalContext";
import { UserContext } from "../../context/UserContext";
import { Link } from "@reach/router";
import SingleClassForm from "../single_class/SingleClassForm";
import { formatMonto, getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";
import { getFriendlyLocalDateTimeString, getFriendlyLocalTimeString } from "../../utils/dates";

const ScheduleAdminClass = ({ singleClass, handleCallback }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { setPropiedadClase, eliminarClase } = useContext(
    SingleClassContext
  );

  const { user } = useContext(UserContext);
  const appconfig = useContext(AppConfigContext);

  const handleEdit = () => {
    modalComponent(
      "Editar Clase",
      <SingleClassForm
        single_class_id={singleClass.single_class_id}
        handleCallback={handleCallback}
        modifier={setPropiedadClase}
        handleCancel={clearModal}
      />
    );
  };

  const handleDelete = () => {
    modalComponent(
      "Eliminar Clase",
      <div>
        <p>
          ¿Estás segura que deseas eliminar la clase de{" "}
          {singleClass.class_type.name} del{" "}
          {getFriendlyLocalDateTimeString(singleClass.class_date)}?
        </p>
        <button
          className="btn btn-danger btn-sm"
          onClick={() =>
            eliminarClase(singleClass.single_class_id, handleCallback)
          }
        >
          Eliminar
        </button>
      </div>
    );
  };

  const renderButtons = () => {
    if (["super_admin", "admin", "manager"].includes(user.role)) {
      return (
        <div>
          <button className="btn btn-primary btn-sm mx-2" onClick={handleEdit}>
            <i className="fa fa-edit" />
          </button>
          <button
            className="btn btn-outline-danger btn-sm mx-2"
            onClick={handleDelete}
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      );
    }
  };

  const renderRating = () => {
    let components = [];
    if (singleClass.class_stars_average !== null
      && singleClass.class_stars_average !== undefined) {
      components.push(
        <span key="class" className="me-3">
          {formatMonto(singleClass.coach_stars_average)}{" "}
          <i className="fa text-warning fa-star ms-1"></i>
        </span>
      );
    }
    if (
      singleClass.class_stars_average !== null
      && singleClass.class_stars_average !== undefined
    ) {
      components.push(
        <span key="coach">
          {formatMonto(singleClass.coach_stars_average)}
          <i className="fa text-warning fa-star ms-1"></i>
        </span>
      );
    }
    if (components.length === 0) {
      components.push(
        <span key="none" className="small text-muted">
          Sin calificar
        </span>
      );
    }
    if(getValue(appconfig, "ratings_enabled", "boolean")) {
      return <div className="mb-2">{components}</div>;
    }
  };

  const renderInstructors = () => {
    return singleClass.class_instructors
      .filter(({ instructor }) => instructor !== null)
      .map((class_instructor) => (
        <span key={class_instructor.instructor_id}>
          {class_instructor.instructor?.name}
        </span>
      ));
  };

  const renderBadges = () => {
    const components = [];
    if (Array.isArray(singleClass.tags)) {
      singleClass.tags.forEach((tag) =>
        components.push(
          <span
            className="badge bg-accent badge-pill"
            key={tag.name}
          >
            {tag.name}
          </span>
        )
      );
    }
    return <div className="d-block mb-1">{components}</div>;
  };

  return (
    <div className="schedule-class me-2 border border-dark py-3 px-2 my-2">
      <p className="mb-1 bold">
        <i className={singleClass.icon} /> {singleClass.class_type?.name}
      </p>
      <p className="small mb-1 small">{singleClass.description}</p>
      <p className="small font-weight-bold mb-1">
        <i className="far fa-clock"></i>{" "}
        {getFriendlyLocalTimeString(singleClass.class_date)}
      </p>
      <p className="mb-1">{renderInstructors()}</p>
      <Link
        className="mb-2 text-primary d-block"
        to={`/myadmin/asistentes/${singleClass.single_class_id}`}
      >
        <i className="fa fa-user me-1" />{" "}
        {singleClass.class_reservations.length} / {singleClass.capacity}
      </Link>
      {renderRating()}
      {renderBadges()}
      {renderButtons()}
    </div>
  );
};

export default ScheduleAdminClass;