import api from "./api";

const route = "/class_reservation_penaltys";

const ClassReservationPenaltyService = {
  getAllClassReservationPenaltys: () =>  api.get(`${route}/admin`),
  getClassReservationPenaltys: (customer_id) => api.get(`${route}/admin/${customer_id}`),
  getSingleClassReservationPenalty: (ClassReservationPenalty_id) =>
    api.get(`${route}/${ClassReservationPenalty_id}`),
  postClassReservationPenalty: (ClassReservationPenalty) =>
    api.post(route, { ...ClassReservationPenalty }),
  putClassReservationPenalty: (ClassReservationPenalty) =>
    api.put(route, { ...ClassReservationPenalty }),
  deleteClassReservationPenalty: (ClassReservationPenalty) =>
    api.delete(`${route}/${ClassReservationPenalty}`),
};

export default ClassReservationPenaltyService;