import { Link } from "@reach/router";
import React, { useContext } from "react";
import { displayDayTime } from "../../utils/dates";
import AvailableClassForm from "./AvailableClassForm";
import { ModalContext } from "../../context/ModalContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import { getFriendlyLocalDateTimeString } from "../../utils/dates";
import { AvailableClassesContext } from "../../context/AvailableClassesContext";
import { getValue } from "../../utils";

const AvailableClassRow = ({
  available_class,
  handleCallback,
  selectable,
  selected,
  onToggle,
}) => {
  const { setAvailableClass, deleteAvailableClass } = useContext(
    AvailableClassesContext
  );
  const { clearModal, modalComponent } = useContext(ModalContext);
  const appconfig = useContext(AppConfigContext);

  const allow_class_guests = getValue(
    appconfig,
    "allow_class_guests",
    "boolean"
  );

  const handleCancel = () => {
    setAvailableClass(null);
    clearModal();
  };

  const handleEdit = () => {
    setAvailableClass(available_class);
    modalComponent(
      "Editar Clase",
      <AvailableClassForm
        handleCancel={handleCancel}
        handleCallback={handleCallback}
      />
    );
  };

  const handleDelete = () => {
    modalComponent(
      "Eliminar Clase",
      <div>
        <p>
          ¿Estás segura que deseas eliminar el crédito #
          {available_class.available_class_id} del cliente?
        </p>
        <p>Esta acción NO puede deshacerse.</p>
        <div className="row">
          <div className="col">
            <button onClick={clearModal} className="btn w-100 text-muted">
              Cancelar
            </button>
          </div>
          <div className="col">
            <button
              onClick={() =>
                deleteAvailableClass(
                  available_class.available_class_id,
                  handleCallback
                )
              }
              className="btn w-100 btn-danger"
            >
              Eliminar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderAvailableClassId = () => {
    if (selectable) {
      return (
        <td>
          <input
            className={`
              form-check-input me-2 
              ${selected ? "bg-primary" : ""}
            `}
            onChange={() => onToggle(available_class.available_class_id)}
            checked={selected}
            type="checkbox"
          />
          {available_class.available_class_id}
        </td>
      );
    } else {
      return <td>{available_class.available_class_id}</td>;
    }
  };

  return (
    <tr>
      {renderAvailableClassId()}
      <td>{getFriendlyLocalDateTimeString(available_class.createdAt)}</td>
      <td>
        {available_class.start_date !== null
          ? getFriendlyLocalDateTimeString(available_class.start_date)
          : ""}
      </td>
      <td>{getFriendlyLocalDateTimeString(available_class.expiration_date)}</td>
      <td>{available_class.purchase_id}</td>
      {allow_class_guests && (
        <td>
          {getValue(available_class, "allow_guest", "boolean") ? (
            <i className="fa fa-check-circle text-success" />
          ) : (
            <i className="fa fa-times text-danger" />
          )}
        </td>
      )}
      <td>
        {available_class.class_reservation &&
          available_class.class_reservation !== null && (
            <Link
              to={`/myadmin/asistentes/${available_class.class_reservation.single_class_id}`}
            >
              <i className="fa fa-eye" />{" "}
              {displayDayTime(
                available_class.class_reservation.single_class.class_date
              )}
            </Link>
          )}
      </td>
      <td>
        {available_class.class_reservation_id === null && [
          <button
            key="edit"
            onClick={handleEdit}
            className="btn btn-sm btn-outline-dark"
          >
            <i className="fa fa-edit" />
          </button>,
          <button
            key="delete"
            onClick={handleDelete}
            className="btn ms-2 btn-sm btn-outline-danger"
          >
            <i className="fa fa-trash" />
          </button>,
        ]}
      </td>
    </tr>
  );
};

export default AvailableClassRow;
