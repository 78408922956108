import React, { useContext } from "react";
import AvailableClassRow from "./AvailableClassRow";
import { AppConfigContext } from "../../context/AppConfigContext";
import { getValue } from "../../utils";

const AvailableClassesTable = ({ 
  available_classes, 
  handleCallback,
  allSelected,
  setAllSelected,
  itemsSelected,
  setItemsSelected
}) => {

  const appconfig = useContext(AppConfigContext);

  const allow_class_guests = getValue(
    appconfig,
    "allow_class_guests",
    "boolean"
  );

  const toggleSelectAll = () => {
    if(allSelected) {
      setAllSelected(false);
      setItemsSelected([]);
    } else {
      setAllSelected(true);
      setItemsSelected(available_classes.map(item => {
        return item.available_class_id;
      }));
    }
  };

  const handleToggleElement = (elementId) => {
    let newElements;

    if(itemsSelected.includes(elementId)) {
      newElements = itemsSelected.filter(id => {
        return id !== elementId;
      });

    } else {
      newElements = [...itemsSelected, elementId];
    }

    setItemsSelected(newElements);
  }

  const renderAvailableClasses = () => {
    if (Array.isArray(available_classes)) {
      if (available_classes.length === 0) {
        <p>No hay clases disponibles.</p>;
      }

      return (
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="bold bg-light">
                <td>
                  <input
                    className={`
                      form-check-input me-2 
                      ${allSelected ? 'bg-primary' : ''}
                    `}
                    onChange={toggleSelectAll}
                    checked={allSelected}
                    type="checkbox"
                  />
                  #ID
                </td>
                <td>Creado</td>
                <td>Inicio</td>
                <td>Expira en</td>
                <td>#ID Compra</td>
                {allow_class_guests && (
                  <td>Invitados</td>
                )}
                <td>Usado</td>
                <td>Acciones</td>
              </tr>
            </thead>
            <tbody>
              {available_classes.map((available_class) => {
                const elementId = available_class.available_class_id;
                const selected = itemsSelected.includes(elementId);

                return (
                  <AvailableClassRow
                    key={elementId}
                    available_class={available_class}
                    handleCallback={handleCallback}
                    selectable
                    selected={selected}
                    onToggle={handleToggleElement}
                  />
                )
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return <div className="spinner-border" />;
  };

  return <div>{renderAvailableClasses()}</div>;
};

export default AvailableClassesTable;
