import React from "react";
import { useContext } from "react";
import CreatableSelect from "react-select/creatable";
import { CustomerContext } from "../../context/CustomerContext";
import { useState } from "react";
import { useEffect } from "react";

const SelectCustomerTags = () => {
  const [tagOptions, setTagOptions] = useState([]);
  const [customerTags, setCustomerTags] = useState([]);

  const {
    customer,
    allCustomerTags,
    getCustomerTagOptions,
    setPropiedadCustomer,
  } = useContext(CustomerContext);

  useEffect(() => {
    getCustomerTagOptions({ customer_id: customer.customer_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(allCustomerTags)) {
      const options = allCustomerTags.map((tag) => ({
        label: tag.name,
        value: tag.tag_id,
      }));

      setTagOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCustomerTags]);

  useEffect(() => {
    if (Array.isArray(customer?.tags)) {
      if (customer.tags.length > 0) {
        const formattedOptions = customer.tags.map((tag) => ({
          label: tag.name,
          value: tag.tag_id,
        }));

        setCustomerTags(formattedOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.tags]);

  useEffect(() => {
    const updatedTags = getTagsFormData();
    updateTagOptions({ tagsToDelete: updatedTags.tagsToDelete });

    setPropiedadCustomer("updatedTags", updatedTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerTags]);

  const updateTagOptions = ({ tagsToDelete }) => {
    const newOptions = tagsToDelete
      .filter((tag) => !tagOptions.find((obj) => obj.tag_id === tag.tag_id))
      .map((tag) => ({
        label: tag.name,
        value: tag.tag_id,
      }));

    setTagOptions([...tagOptions, ...newOptions]);
  };

  const getTagsFormData = () => {
    const newTags = getNewTags();
    const tagsToAttach = getTagsToAttach();
    const tagsToDelete = getTagsToDelete();

    return {
      newTags,
      tagsToAttach,
      tagsToDelete,
    };
  };

  const getNewTags = () => {
    return [...customerTags].filter((current) => current.tag_id === "new");
  };

  const getTagsToAttach = () => {
    const tagsToAttach = [...customerTags].filter((current) => {
      const isNotNew = current.tag_id !== "new";
      const customerAlreadyHasTag = customer.tags?.find(
        (tag) => tag.tag_id === current.tag_id
      );
      return isNotNew && !customerAlreadyHasTag;
    });
    return tagsToAttach;
  };

  const getTagsToDelete = () => {
    let tagsToDelete = [];
    if (Array.isArray(customer?.tags) && customer?.tags.length > 0) {
      tagsToDelete = customer?.tags.filter(
        (current) => !customerTags.find((tag) => tag.tag_id === current.tag_id)
      );
    }

    return tagsToDelete;
  };

  return (
    <div className="row">
      <label>Etiquetas del Cliente</label>
      <CreatableSelect
        isClearable={false}
        isMulti
        formatCreateLabel={(inputValue) => `Crear etiqueta: ${inputValue}`}
        noOptionsMessage={() => "No hay opciones, escribe para crear una nueva"}
        placeholder={"Selecciona o crea una o varias etiquetas"}
        options={tagOptions}
        value={customerTags}
        onCreateOption={(inputValue) =>
          setCustomerTags([
            ...customerTags,
            {
              label: inputValue,
              name: inputValue,
              tag_id: "new",
            },
          ])
        }
        onChange={(newValue) => setCustomerTags(newValue)}
      />
    </div>
  );
};

export default SelectCustomerTags;
