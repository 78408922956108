import React, { useContext, useEffect, useState } from "react";
import SearchableMultipleSelect from "../common/SearchableMultipleSelect";
import { DiscountsContext } from "../../context/DiscountsContext";
import { PackagesContext } from "../../context/PackageContext";
import { getValue, hideModal } from "../../utils";
import Switch from "react-switch";

const DescuentoForm = ({ discount_id }) => {
  const [type, setType] = useState("percent");
  const {
    descuento,
    getDescuento,
    postDescuento,
    createDescuento,
    setPropiedadDescuento,
    appendProductDiscount,
    removeProductDiscount,
  } = useContext(DiscountsContext);
  const { paquetes, getAllPaquetes } = useContext(PackagesContext);

  useEffect(() => {
    if (isNaN(discount_id)) {
      createDescuento();
    } else {
      getDescuento(discount_id);
    }
    getAllPaquetes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount_id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    postDescuento({ ...descuento, is_percent: type === "percent" });
  };

  const renderForm = () => {
    if (descuento && descuento !== null) {
      return (
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col-6">Disponible</div>
            <div className="col-6 text-end">
              <Switch
                checked={getValue(descuento, "available", "boolean")}
                onChange={(checked) =>
                  setPropiedadDescuento("available", checked)
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label>Código</label>
              <input
                type="text"
                className="form-control mb-3"
                value={getValue(descuento, "code")}
                onChange={(e) => setPropiedadDescuento("code", e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>Cantidad por Cliente</label>
              <input
                type="number"
                className="form-control mb-3"
                value={getValue(descuento, "limit_per_customer")}
                onChange={(e) =>
                  setPropiedadDescuento("limit_per_customer", e.target.value)
                }
              />
            </div>
          </div>
          <label>Descripción</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(descuento, "description")}
            onChange={(e) =>
              setPropiedadDescuento("description", e.target.value)
            }
          />
          <div className="row">
            <div className="col-6">
              <label>Cantidad</label>
              <input
                type="number"
                className="form-control mb-3"
                value={getValue(descuento, "amount")}
                onChange={(e) =>
                  setPropiedadDescuento("amount", e.target.value)
                }
              />
            </div>
            <div className="col-6">
              <label>Tipo de Descuento</label>
              <select
                className="form-control mb-3"
                value={getValue(descuento, "type")}
                onChange={(e) => setPropiedadDescuento("type", e.target.value)}
              >
                <option value="percent">Porcentaje (%)</option>
                <option value="amount">Cantidad Fija ($)</option>
                <option value="class_amount">Cantidad de Clases</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label>Fecha Inicio</label>
              <input
                type="date"
                className="form-control mb-3"
                value={getValue(descuento, "start_date")}
                onChange={(e) =>
                  setPropiedadDescuento("start_date", e.target.value)
                }
              />
            </div>
            <div className="col-6">
              <label>Fecha Fin</label>
              <input
                type="date"
                className="form-control mb-3"
                value={getValue(descuento, "expiration_date")}
                onChange={(e) =>
                  setPropiedadDescuento("expiration_date", e.target.value)
                }
              />
            </div>
          </div>

          <SearchableMultipleSelect
            items={paquetes}
            key_id={"class_package_id"}
            itemName={"paquete"}
            fetchItems={getAllPaquetes}
            label="Restringir a Paquetes"
            addItem={appendProductDiscount}
            removeItem={removeProductDiscount}
            selected={descuento.discount_class_packages}
            CustomComponent={({ item }) => <span>{item.title}</span>}
          />

          <div className="row mb-3">
            <div className="col-6">Sólo para primer pago</div>
            <div className="col-6 text-end">
              <Switch
                checked={getValue(descuento, "first_invoice_only", "boolean")}
                onChange={(checked) =>
                  setPropiedadDescuento("first_invoice_only", checked)
                }
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">Sólo para clientes nuevos</div>
            <div className="col-6 text-end">
              <Switch
                checked={getValue(descuento, "first_purchase_only", "boolean")}
                onChange={(checked) =>
                  setPropiedadDescuento("first_purchase_only", checked)
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 text-center">
              <button
                className="btn btn-link text-secondary mt-2 "
                onClick={(e) => {
                  e.preventDefault();
                  hideModal();
                }}
              >
                Cancelar
              </button>
            </div>

            <div className="col-6">
              <button type="submit" className="btn btn-primary w-100 mt-2">
                Guardar
              </button>
            </div>
          </div>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default DescuentoForm;
