import React, { useContext } from "react";
import ReactSwitch from "react-switch";
import { getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";

const SingleFeatureSetting = ({ appConfigKey, title, description }) => {
  const appconfig = useContext(AppConfigContext);
  const { setAppConfigProperty } = appconfig;
  return (
    <div className="row mx-auto py-3 border-top align-items-center">
      <div className="col-2 col-xl-1">
        <ReactSwitch
          checked={getValue(appconfig, appConfigKey, "boolean")}
          onChange={(checked) => setAppConfigProperty(appConfigKey, checked)}
        />
      </div>
      <div className="col-10 col-xl-11">
        <h3 className="h5">{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default SingleFeatureSetting;
