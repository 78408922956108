import React from "react";
import { Link } from "@reach/router";
import { isToday } from "../../utils/dates";

const AsistenteRow = ({
  user,
  postGear,
  asistente,
  postAttend,
  postPayment,
  confirmCancel,
  handleCallback,
  single_class_id,
  is_special_event,
}) => {
  const sendWhatsApp = (telefono) => {
    telefono = String(telefono).replace("+52", "");
    window.open(`https://wa.me/521${telefono}`, "_blank");
  };

  const renderCustomerName = () => {
    if (asistente.guest && asistente.guest !== null) {
      return asistente.guest;
    }
    if (asistente.customer && asistente.customer !== null) {
      let name = asistente.customer.name;
      if (asistente.customer.last_name !== null) {
        name += " " + asistente.customer.last_name;
      }
      return name;
    }
    return "Sin Nombre";
  };

  const renderCustomer = () => {
    const name = renderCustomerName();
    if (["super_admin", "admin", "manager"].includes(user.role)) {
      return (
        <Link to={`/myadmin/customer/${asistente.customer_id}`}>{name}</Link>
      );
    }
    return <span>{name}</span>;
  };

  const renderBirthday = () => {
    if (asistente.birthdate && asistente.birthdate !== null) {
      if (isToday(asistente.birthdate)) {
        return <i className="fas fa-birthday-cake me-2"></i>;
      }
    }
  };

  const renderCustomerContact = () => {
    if (["super_admin", "manager", "admin"].includes(user.role)) {
      return [
        <td key="instagram">
          <i className="fab fa-instagram me-2"></i>
          {"@"}
          {asistente.instagram}
        </td>,
        <td key="email">
          <i className="fa fa-envelope me-2"></i>
          {asistente.email}
        </td>,
        <td key="whatsapp">
          <button
            className="me-2 btn-sm btn btn-success"
            onClick={() => sendWhatsApp(asistente.phone)}
          >
            <i className="fab fa-whatsapp"></i>
          </button>
          {asistente.phone}
        </td>,
      ];
    }
  };

  const renderCancelButton = () => {
    if (["super_admin", "admin", "manager"].includes(user.role)) {
      return (
        <button
          className="btn btn-sm btn-outline-danger mx-2"
          onClick={() => confirmCancel(asistente)}
        >
          <i className="fa fa-times"></i>
        </button>
      );
    }
  };

  const renderCustomerTags = () => {
    if (Array.isArray(asistente?.customer?.tags)) {
      return asistente.customer.tags.map((tag) => {
        return (
          <span key={tag.tag_id} className="badge badge-pill bg-secondary me-1">
            {tag.content}
          </span>
        );
      });
    }
  };

  return (
    <tr className="align-middle small bg-white">
      <td>
        {asistente.enabled_by !== null && (
          <i className="fa fa fa-user-shield text-primary"></i>
        )}{" "}
        {renderBirthday()}
        {renderCustomer()}
      </td>
      <td>{renderCustomerTags()}</td>
      {renderCustomerContact()}
      <td>{asistente.spot}</td>
      <td>
        <div className="btn-group">
        <button
            className={`btn btn-sm btn-${
              !asistente.attend && asistente.attend !== null ? "danger" : "light border"
            }`}
            disabled={!asistente.attend && asistente.attend !== null}
            onClick={() =>
              postAttend(
                asistente.class_reservation_id,
                false,
                handleCallback
              )
            }
          >
            No
          </button>
          <button
            className={`btn btn-sm btn-${
              asistente.attend && asistente.attend !== null ? "success" : "light border"
            }`}
            disabled={asistente.attend && asistente.attend !== null}
            onClick={() =>
              postAttend(
                asistente.class_reservation_id,
                true,
                handleCallback
              )
            }
          >
            Si
          </button>
        </div>
      </td>
      {!is_special_event && (
        <td>
          <button
            className={`btn btn-sm ${
              !asistente.gear ? "btn-outline-secondary" : "text-primary"
            } me-2`}
            onClick={() =>
              postGear(
                asistente.class_reservation_id,
                !asistente.gear,
                handleCallback
              )
            }
          >
            <i className="fas fa-shoe-prints"></i>
          </button>
          {asistente.is_paid !== null && asistente.payment_method_id > 3 && (
            <button
              className={`btn btn-sm btn-outline-${
                asistente.is_paid ? "danger" : "success"
              } mx-2`}
              onClick={() =>
                postPayment(
                  asistente.class_reservation_id,
                  !asistente.is_paid,
                  single_class_id
                )
              }
            >
              <i className="fa fa-money-bill"></i>
            </button>
          )}
          {renderCancelButton()}
        </td>
      )}
    </tr>
  );
};

export default AsistenteRow;
