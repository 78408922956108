import React, { useContext, useEffect } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import { ClassReservationPenaltysContext } from "../../context/ClassReservationPenaltysContext";
import PenaltiesTable from "../../components/penalties/PenaltiesTable";

const AdminPenalties = () => {
  const { getAllClassReservationPenaltys } = useContext(
    ClassReservationPenaltysContext
  );

  useEffect(() => {
    getAllClassReservationPenaltys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid">
      <PanelTitle title="Penalizaciones" hideButton />
      <div className="card p-3 shadow">
        <PenaltiesTable displayCustomer />
      </div>
    </div>
  );
};

export default AdminPenalties;
