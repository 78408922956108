import React, { useContext, useEffect } from "react";
import PenaltyForm from "./PenaltyForm";
import PanelTitle from "../global/PanelTitle";
import PenaltiesTable from "./PenaltiesTable";
import { ModalContext } from "../../context/ModalContext";
import { ClassReservationPenaltysContext } from "../../context/ClassReservationPenaltysContext";

const CustomerPenalties = ({ customer_id }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const {
    setClassReservationPenalty,
    getClassReservationPenaltys,
    createClassReservationPenalty,
  } = useContext(ClassReservationPenaltysContext);

  useEffect(() => {
    fetchPenalties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id]);

  const fetchPenalties = () => getClassReservationPenaltys(customer_id);

  const handleCancel = () => {
    clearModal();
    setClassReservationPenalty(null);
  };

  const handleCreate = () => {
    createClassReservationPenalty();
    modalComponent(
      "Agregar Penalización",
      <PenaltyForm
        customer_id={customer_id}
        handleCancel={handleCancel}
        handleCallback={fetchPenalties}
      />
    );
  };

  return (
    <div className="card p-3 my-3">
      <PanelTitle title="Penalizaciones" onClick={handleCreate} />
      <PenaltiesTable
        customer_id={customer_id}
        handleCallback={fetchPenalties}
      />
    </div>
  );
};
export default CustomerPenalties;
