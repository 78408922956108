import React, { createContext, useReducer, useContext } from "react";
import ClassReservationPenaltysService from "../services/ClassReservationPenaltysService";
import ClassReservationPenaltysReducer from "../reducers/ClassReservationPenaltysReducer";
import {
  CLASSRESERVATIONPENALTYS_RECEIVED,
  SET_CLASSRESERVATIONPENALTY,
  CREATE_CLASSRESERVATIONPENALTY,
  SET_PROPERTY_CLASSRESERVATIONPENALTY,
} from "../types/ClassReservationPenaltys";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  class_reservation_penalties: null,
  class_reservation_penalty: null,
};

export const ClassReservationPenaltysContext = createContext(initialState);

export const ClassReservationPenaltysProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    ClassReservationPenaltysReducer,
    initialState
  );

  const { alert, success, clearModal } = useContext(ModalContext);

  const getAllClassReservationPenaltys = () => {
    ClassReservationPenaltysService.getAllClassReservationPenaltys()
      .then((response) => {
        const { class_reservation_penaltys } = response.data;
        dispatch({
          type: CLASSRESERVATIONPENALTYS_RECEIVED,
          payload: class_reservation_penaltys,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };


  const getClassReservationPenaltys = (customer_id) => {
    ClassReservationPenaltysService.getClassReservationPenaltys(customer_id)
      .then((response) => {
        const { class_reservation_penaltys } = response.data;
        dispatch({
          type: CLASSRESERVATIONPENALTYS_RECEIVED,
          payload: class_reservation_penaltys,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleClassReservationPenalty = (ClassReservationPenalty_id) => {
    ClassReservationPenaltysService.getSingleClassReservationPenalty(
      ClassReservationPenalty_id
    )
      .then((response) => {
        const { class_reservation_penalty } = response.data;
        dispatch({
          type: SET_CLASSRESERVATIONPENALTY,
          payload: class_reservation_penalty,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setClassReservationPenalty = (class_reservation_penalty) => {
    dispatch({
      type: SET_CLASSRESERVATIONPENALTY,
      payload: class_reservation_penalty,
    });
  };

  const createClassReservationPenalty = () => {
    dispatch({ type: CREATE_CLASSRESERVATIONPENALTY });
  };

  const setPropertyClassReservationPenalty = (key, value) => {
    dispatch({
      type: SET_PROPERTY_CLASSRESERVATIONPENALTY,
      payload: { key, value },
    });
  };

  const saveClassReservationPenalty = (class_reservation_penalty, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = ClassReservationPenaltysService.putClassReservationPenalty;
    if (
      isNaN(parseInt(class_reservation_penalty.class_reservation_penalty_id))
    ) {
      service = ClassReservationPenaltysService.postClassReservationPenalty;
    }
    service(class_reservation_penalty)
      .then(() => {
        success("Penalización guardada.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteClassReservationPenalty = (
    class_reservation_penalty_id,
    callback
  ) => {
    dispatch({ type: SHOW_SPINNER });
    ClassReservationPenaltysService.deleteClassReservationPenalty(
      class_reservation_penalty_id
    )
      .then(() => {
        success("Penalización eliminada.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <ClassReservationPenaltysContext.Provider
      value={{
        ...state,
        setClassReservationPenalty,
        getClassReservationPenaltys,
        saveClassReservationPenalty,
        deleteClassReservationPenalty,
        createClassReservationPenalty,
        getAllClassReservationPenaltys,
        getSingleClassReservationPenalty,
        setPropertyClassReservationPenalty,
      }}
    >
      {children}
    </ClassReservationPenaltysContext.Provider>
  );
};
