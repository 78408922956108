import React, { useContext } from "react";
import CustomerRow from "./CustomerRow";
import SortableHeader from "./SortableHeader";
import { UserContext } from "../../context/UserContext";

const CustomersTable = ({
  customers,
  exclude = [],
  extraFields,
  setSort,
  selected,
  direction,
  setDirection,
  showCancelReason,
}) => {
  const { user } = useContext(UserContext);

  const renderCustomers = () => {
    if (Array.isArray(customers)) {
      if (customers.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay clientes registradas.</td>
          </tr>
        );
      }
      return customers.map((customer) => {
        return (
          <CustomerRow
            customer={customer}
            exclude={exclude}
            key={customer.customer_id}
            extraFields={extraFields}
            showCancelReason={showCancelReason}
          />
        );
      });
    }
  };

  const renderExtraFields = () => {
    if (Array.isArray(extraFields)) {
      return extraFields.map((field) => <td key={field.key}>{field.label}</td>);
    }
    if (showCancelReason) {
      return <td>Razón</td>;
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            {!exclude.includes("customer_id") && (
              <SortableHeader
                column="customer_id"
                label="#ID"
                selected={selected}
                setSort={setSort}
                direction={direction}
                setDirection={setDirection}
              />
            )}
            <SortableHeader
              column="name"
              label="Nombre"
              selected={selected}
              setSort={setSort}
              direction={direction}
              setDirection={setDirection}
            />
            <SortableHeader
              column="email"
              label="Correo"
              selected={selected}
              setSort={setSort}
              direction={direction}
              setDirection={setDirection}
            />
            <SortableHeader
              column="phone"
              label="Teléfono"
              setSort={setSort}
              selected={selected}
              direction={direction}
              setDirection={setDirection}
            />
            {!exclude.includes("birthdate") && (
              <SortableHeader
                column="birthdate"
                label="Cumpleaños"
                setSort={setSort}
                selected={selected}
                direction={direction}
                setDirection={setDirection}
              />
            )}
            {!exclude.includes("tags") && (
              <SortableHeader
                column="tags"
                label="Etiquetas"
                setSort={setSort}
                selected={selected}
                direction={direction}
                setDirection={setDirection}
              />
            )}
            {["super_admin", "admin"].includes(user.role) &&
              !exclude.includes("value") && (
                <SortableHeader
                  column="value"
                  label="Valor"
                  setSort={setSort}
                  selected={selected}
                  direction={direction}
                  setDirection={setDirection}
                />
              )}
            {renderExtraFields()}
          </tr>
        </thead>
        <tbody>{renderCustomers()}</tbody>
      </table>
    </div>
  );
};

export default CustomersTable;
