import React, { useContext } from "react";
import moment from "moment-timezone";
import SecureInput from "./SecureInput";
import { AppConfigContext } from "../../context/AppConfigContext";
import FilesLocationInput from "./FilesLocationInputs";

const AdvancedSettings = () => {
  const {
    timezone,
    stripe_secret,
    brevo_api_key,
    paypal_client_id,
    stripe_public,
    paypal_client_secret,
    setAppConfigProperty,
    stripe_webhook_secret,
  } = useContext(AppConfigContext);

  const timezones = [
    { label: "Seleccionar...", value: "" },
    ...moment.tz
      .zonesForCountry("MX")
      .map((name) => ({ label: name, value: name })),
  ];

  return (
    <div className="col-12 px-0 overflow-x-hidden">
      <h2 className="h4 mb-2 pb-2 border-bottom">Precaución</h2>
      <p className="text-danger bold">
        Modificar cualquier configuración en esta pestaña podría causar que tu
        plataforma deje de funcionar.
      </p>

      <SecureInput
        smallTitle
        hideValue
        type={"select"}
        value={timezone}
        label={"Zona Horaria"}
        selectOptions={timezones}
        handleChange={(event) => {
          setAppConfigProperty("timezone", event.target.value);
        }}
      />

      <FilesLocationInput />

      <h3 className="h4 border-bottom pb-3 mb-3">
        Pasarelas / Procesadores de Pago
      </h3>

      <div className="container-fluid px-0">
        <div className="row mb-4">
          <div className="col-12 col-md-6">
            <h3 className="h5 mb-3">Stripe</h3>
            <SecureInput
              smallTitle
              type={"text"}
              hideValue
              label={"Stripe Public Key"}
              value={stripe_public}
              handleChange={(event) => {
                setAppConfigProperty("stripe_public", event.target.value);
              }}
            />
            <SecureInput
              smallTitle
              type={"text"}
              hideValue
              label={"Stripe Secret"}
              value={stripe_secret}
              handleChange={(event) => {
                setAppConfigProperty("stripe_secret", event.target.value);
              }}
            />
            <SecureInput
              smallTitle
              type={"text"}
              hideValue
              label={"Stripe WebHook Secret"}
              value={stripe_webhook_secret}
              handleChange={(event) => {
                setAppConfigProperty(
                  "stripe_webhook_secret",
                  event.target.value
                );
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <h3 className="h5 mb-3">PayPal (opcional)</h3>
            <SecureInput
              smallTitle
              type={"text"}
              hideValue
              label={"PayPal Client Id"}
              value={paypal_client_id}
              handleChange={(event) => {
                setAppConfigProperty("paypal_client_id", event.target.value);
              }}
            />
            <SecureInput
              smallTitle
              type={"text"}
              hideValue
              label={"PayPal Client Secret"}
              value={paypal_client_secret}
              handleChange={(event) => {
                setAppConfigProperty("stripe_secret", event.target.value);
              }}
            />
          </div>
        </div>
      </div>

      <h3 className="h4 border-bottom pb-3 mb-3">Servicios de Mailing</h3>

      <div className="col-12 mx-auto px-0 mb-3">
        <SecureInput
          smallTitle
          type={"text"}
          hideValue
          label={"Brevo API Key"}
          value={brevo_api_key}
          handleChange={(event) => {
            setAppConfigProperty("brevo_api_key", event.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default AdvancedSettings;
