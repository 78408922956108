import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { ModalContext } from "../../context/ModalContext";
import { PackagesContext } from "../../context/PackageContext";
import { PurchasesContext } from "../../context/PurchasesContext";
import RevokeForm from "../../components/purchases/RevokeForm";
import CancelForm from "../../components/purchases/CancelForm";
import PurchasesTable from "../../components/purchases/PurchasesTable";
import InvoicesTable from "../../components/invoices/InvoicesTable";
import PanelTitle from "../../components/global/PanelTitle";
import AddPurchaseForm from "../../components/purchases/AddPurchaseForm";
import InvoiceForm from "../../components/invoices/InvoiceForm";
import { UserContext } from "../../context/UserContext";
import { PaymentMethodsContext } from "../../context/PaymentMethodsContext";
import { NotesContext } from "../../context/NotesContext";
import CustomerActions from "../../components/customers/CustomerActions";
import ReservacionesTable from "../../components/reservaciones/ReservacionesTable";
import AvailableClassesPanel from "../../components/availableClasses/AvailableClassesPanel";
import OnlineAccessTable from "../../components/customers/OnlineAccessTable";
import CustomerPenalties from "../../components/penalties/CustomerPenalties";
import CustomerData from "../../components/customers/CustomerData";
import NotesOffcanvas from "../../components/notes/NotesOffcanvas";
import { AppConfigContext } from "../../context/AppConfigContext";
import ConfirmDelete from "../../components/common/ConfirmDelete";
import { navigate } from "@reach/router";
import { getValue } from "../../utils";

const AdminSingleUsuario = ({ customer_id }) => {
  const [currentTab, setCurrentTab] = useState("Compras");

  const {
    customer,
    getCustomer,
    clearCustomer,
    extenderAcceso,
    deleteCustomer,
  } = useContext(CustomerContext);
  const { getNotesByCustomer } = useContext(NotesContext);
  const { paquetes, getAllPaquetes } = useContext(PackagesContext);

  const { clearModal, modalComponent, offcanvasComponent } =
    useContext(ModalContext);

  const appconfig = useContext(AppConfigContext);

  const unlimited_membership_penalties_enabled = getValue(
    appconfig,
    "unlimited_membership_penalties_enabled",
    "boolean"
  );
  const single_class_enabled = getValue(
    appconfig,
    "single_class_enabled",
    "boolean"
  );
  const videos_enabled = getValue(appconfig, "videos_enabled", "boolean");

  const { payment_methods, getPaymentMethods } = useContext(
    PaymentMethodsContext
  );

  const { cancelPurchase, revokePurchase, editExpiration } =
    useContext(PurchasesContext);

  const { user } = useContext(UserContext);

  useEffect(() => {
    getPaymentMethods();
    getCustomer(customer_id);
    getAllPaquetes();
    getNotesByCustomer(customer_id);

    return clearCustomer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id]);

  const getViewData = () => {
    getCustomer(customer_id);
  };

  const purchaseForm = () => {
    modalComponent(
      "Agregar Acceso",
      <AddPurchaseForm
        customer={customer}
        paquetes={paquetes}
        handleCancel={clearModal}
        extenderAcceso={extenderAcceso}
        paymentMethods={payment_methods}
      />
    );
  };

  const invoiceForm = () => {
    modalComponent(
      "Agregar Cargo",
      <InvoiceForm
        purchases={customer.purchases}
        handleCancel={clearModal}
        paymentMethods={payment_methods}
        handleCallback={getViewData}
      />
    );
  };

  const confirmDelete = () => {
    modalComponent(
      "Precaución",
      <ConfirmDelete
        message={`¿Estás segura que deseas eliminar el cliente ${customer.name} 
          ${customer.last_name} con el correo ${customer.email}`}
        handleDelete={() => deleteCustomer(customer_id)}
        handleCancel={clearModal}
      />
    );
  };

  const confirmRevoke = (purchase) => {
    modalComponent(
      "Precaución",
      <RevokeForm
        purchase={purchase}
        customer_id={customer_id}
        revokePurchase={revokePurchase}
      />
    );
  };

  const confirmCancel = (purchase) => {
    modalComponent(
      "Precaución",
      <CancelForm
        purchase={purchase}
        customer_id={customer_id}
        cancelPurchase={cancelPurchase}
      />
    );
  };

  const renderUsuario = () => {
    if (customer && customer !== null) {
      const isAdmin = ["super_admin", "admin"].includes(user.role);
      return (
        <div className="row">
          <div className="col-12 col-md-6">
            <CustomerData
              customer={customer}
              handleEdit={() =>
                navigate(`/myadmin/customer/${customer_id}/edit`)
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomerActions customer={customer} showTotalPurchases={isAdmin} />
          </div>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  const renderTabs = () => {
    const tabs = ["Compras", "Cargos"];
    if (single_class_enabled) {
      tabs.push(...["Reservaciones", "Créditos"]);
    }
    if (videos_enabled) {
      tabs.push("Accesos");
    }
    if (unlimited_membership_penalties_enabled) {
      tabs.push("Penalizaciones");
    }
    return tabs.map((tab) => (
      <li key={tab} className={`nav-item`} onClick={() => setCurrentTab(tab)}>
        <p
          className={`nav-link mb-0 ${
            tab === currentTab ? "bg-primary" : "text-dark fw-normal"
          } `}
        >
          {tab}
        </p>
      </li>
    ));
  };

  const renderContent = () => {
    switch (currentTab) {
      case "Accesos":
        return <OnlineAccessTable accesses={customer?.online_accesses} />;
      case "Créditos":
        return <AvailableClassesPanel customer_id={customer_id} />;
      case "Reservaciones":
        return (
          <div className="card p-3 pb-1  my-3">
            <PanelTitle title="Reservaciones" hideButton />
            <ReservacionesTable
              reservaciones={customer?.class_reservations}
              deleteCallback={getViewData}
            />
          </div>
        );
      case "Cargos":
        return (
          <div className="card p-3 pb-1  my-3">
            <PanelTitle title="Cargos" onClick={invoiceForm} />
            <InvoicesTable
              user={user}
              customer_id={customer_id}
              invoices={customer !== null ? customer.invoices : []}
            />
          </div>
        );
      case "Penalizaciones":
        return (
          <CustomerPenalties customer_id={customer_id} />
        );
      default:
        return (
          <div className="card p-3 pb-1  my-3">
            <PanelTitle title="Compras" onClick={purchaseForm} />
            <PurchasesTable
              user={user}
              hideColumns={["type", "customer"]}
              editExpiration={editExpiration}
              purchases={customer !== null ? customer.purchases : []}
              confirmRevoke={confirmRevoke}
              confirmCancel={confirmCancel}
            />
          </div>
        );
    }
  };

  const handleNotesOffcanvas = () => {
    offcanvasComponent(
      "Notas",
      <NotesOffcanvas
        customer_id={customer_id}
        handleCallback={() => getNotesByCustomer(customer_id)}
      />
    );
  };

  const renderNotesBtn = () => {
    return (
      <li className={`nav-item`} onClick={handleNotesOffcanvas}>
        <p className="nav-link mb-0 text-dark fw-normal">Notas</p>
      </li>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row pb-2 border-bottom mx-0 mb-3 align-items-center">
        <div className="col col-md-6 ps-0">
          <h1>Cliente</h1>
        </div>
        <div className="col col-md-6 pe-0 text-end"></div>
      </div>

      <div className="card mb-3">{renderUsuario()}</div>

      <div className="card p-3 my-3">
        <div className="row mx-auto w-100">
          <div className="col-12 ps-0">
            <ul className="nav nav-pills">
              {renderTabs()}
              {renderNotesBtn()}
            </ul>
          </div>
        </div>
      </div>

      {renderContent()}

      <button className="btn btn-outline-danger mt-5" onClick={confirmDelete}>
        <i className="fa fa-trash"></i> Eliminar Cliente
      </button>
    </div>
  );
};

export default AdminSingleUsuario;
