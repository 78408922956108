import React, { useContext } from "react";
import { getValue } from "../../utils";
import { SortableHandle } from "react-sortable-hoc";
import { AppConfigContext } from "../../context/AppConfigContext";
import { renderName } from "../../utils/customer";
import { getFriendlyLocalDateString } from "../../utils/dates";

const DragHandle = SortableHandle(() => (
  <span>
    <i className="fas fa-grip-lines"></i>
  </span>
));

const CoachCard = ({ coach, editCoach, deleteCoach, draggable }) => {

  const appconfig = useContext(AppConfigContext);

  const { birthdate, instagram } = coach;

  const renderImage = () => {
    if (coach.file && coach.file !== null) {
      return (
        <img
          src={coach.file.src}
          className="me-1"
          width={40}
          height={40}
          alt="coach"
        />
      );
    }
  };

  const single_class_enabled = getValue(
    appconfig,
    "single_class_enabled",
    "boolean"
  );
  const ratings_enabled = getValue(appconfig, "ratings_enabled", "boolean");
  const videos_enabled = getValue(appconfig, "videos_enabled", "boolean");

  return (
    <div className="card py-md-2 pb-2 p-3 mb-md-0">
      <div
        className="row small align-items-center"
        style={{ fontSize: "14px", userSelect: "none" }}
      >
        {draggable && (
          <div className="col-2">
            <DragHandle />
          </div>
        )}
        <div className="col-8 col-md-2 mb-2">
          <h4 className="bold h5 mb-0">
            {renderImage()}
            {renderName(coach)}
          </h4>
        </div>

        <div className="show-mobile col-4 mb-2 text-end">
          <button
            className="btn btn-outline-primary me-2 btn-sm"
            onClick={() => editCoach(coach)}
          >
            <i className="fa fa-edit"></i>
            <span className="hide-mobile ms-2">Editar</span>
          </button>
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() => deleteCoach(coach)}
          >
            <i className="fa fa-trash"></i>
            <span className="hide-mobile ms-2">Eliminar</span>
          </button>
        </div>

        <div className="col-6 col-md-2 mb-2">
          <a
            href={`https://instagram.com/${getValue(coach, "instagram")}`}
            className="text-primary"
            rel="noreferrer"
            target="_blank"
          >
            <i className="fab fa-instagram me-2" />
            {instagram || <span className="text-muted">No registrado</span>}
          </a>
        </div>

        {!draggable && (
          <div className="col-6 col-md-2 mb-2">
            <i className="fa fa-birthday-cake text-primary me-2" />
            {birthdate !== null ? getFriendlyLocalDateString(birthdate) : <span className="text-muted">No registrado</span>}
          </div>
        )}
        {ratings_enabled && videos_enabled && (
          <div className="col-6 col-md-2 text-md-center text-start mb-2">
            <i className="fa fa-star text-warning show-mobile" />{" "}
            {getValue(coach, "video_stars_average")}
          </div>
        )}

        {ratings_enabled && single_class_enabled && (
          <div className="col-6 col-md-2 text-md-center text-start mb-2">
            <i className="fa fa-certificate text-warning show-mobile" />{" "}
            {getValue(coach, "class_stars_average")}
          </div>
        )}

        <div className="hide-mobile col-md-2 mt-md-0 mt-2 text-md-center text-start">
          <button
            className="btn btn-outline-primary me-2 btn-sm"
            onClick={() => editCoach(coach)}
          >
            <i className="fa fa-edit"></i>
            <span className="hide-mobile ms-2">Editar</span>
          </button>
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() => deleteCoach(coach)}
          >
            <i className="fa fa-trash"></i>
            <span className="hide-mobile ms-2">Eliminar</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoachCard;
