import { useContext } from "react";
import { AppConfigContext } from "../context/AppConfigContext";
import { AdjuntosContext } from "../context/AdjuntosContext";
import { PagesContext } from "../context/PagesContext";

const generalConfig = [
  "business_name",
  "tagline",
  "primary",
  "accent",
  "dark",
  "light",
  "gray",
  "signup_bonus",
  "cancel_timeframe_value",
  "cancel_timeframe_unit",
  "reservation_timeframe_hours",
  "reservation_timeframe_minutes",
  "files_location",
  "head_tracking_codes",
  "body_tracking_codes",
  "stripe_secret",
  "stripe_webhook_secret",
  "stripe_public",
  "location",
  "email",
  "phone",
  "tiktok",
  "twitter",
  "youtube",
  "linkedin",
  "whatsapp",
  "facebook",
  "instagram",
  "pinterest",
  "hero_title",
  "hero_tagline",
  "hero_enabled",
  "about_enabled",
  "brevo_api_key",
  "whatsapp_widget",
  "email_from_name",
  "email_from_address",
  "class_types_enabled",
  "coaches_enabled",
  "packages_enabled",
  "single_class_enabled",
  "hero_order",
  "about_order",
  "class_types_order",
  "coaches_order",
  "packages_order",
  "single_class_order",
  "hero_resource",
  "hero_video",
  "hero_image",
  "app_css",
  "timezone",
  "max_guests_per_class",
  "allow_cancel_after_treshold",
  "warn_manual_book_full_class",
  "allow_manual_book_full_class",
  "allow_manual_book_after_treshold",
  "allow_manual_book_over_max_guests",
  "pages_enabled",
  "videos_enabled",
  "spotmap_enabled",
  "waitlist_enabled",
  "qrticket_enabled",
  "discounts_enabled",
  "allow_class_guests",
  "leaderboard_enabled",
  "testimonials_enabled",
  "single_class_enabled",
  "videos_rating_enabled",
  "subscriptions_enabled",
  "reservation_rating_enabled",
  "allow_restricted_class_types",
  "unlimited_max_am_reservations",
  "unlimited_max_pm_reservations",
  "unlimited_default_penalty_days",
  "unlimited_limit_max_reservations",
  "unlimited_max_daily_reservations",
  "unlimited_max_no_shows_for_penalty",
  "unlimited_membership_penalties_enabled",
  "signup_fee",
];

const useCrudAppConfig = () => {
  const appconfig = useContext(AppConfigContext);

  const {
    images_inputs,
    privacy_policy,
    updateAppConfig,
    fileConfigChanged,
    terms_and_conditions,
    setAppConfigProperty,
  } = appconfig;

  const { postPage } = useContext(PagesContext);
  const { testAdjunto, postAdjunto } = useContext(AdjuntosContext);

  const uploadFiles = async (filesData) => {
    const files = [];

    if (Array.isArray(filesData) && filesData.length > 0) {
      await Promise.all(
        filesData.map(async (input) => {
          return new Promise(async (resolve, reject) => {
            try {
              if (input.file) {
                const fileData = await postAdjunto(input.file);
                const fileName = `${fileData?.data?.file?.name}.${fileData?.data?.file?.type}`;
                const fileSrc = fileData?.data?.file?.src;
                files.push({
                  key: input.key,
                  fileName,
                  fileSrc,
                });
              }
              resolve();
            } catch (error) {
              reject(error);
            }
          });
        })
      );
    }

    return files;
  };

  const saveAppConfigPages = async () => {
    if (terms_and_conditions && terms_and_conditions !== null)
      await postPage(terms_and_conditions);
    if (privacy_policy && privacy_policy !== null)
      await postPage(privacy_policy);
  };

  const saveAppConfig = async () => {
    setAppConfigProperty("loading", true);

    if (fileConfigChanged) {
      const result = await testAdjunto();
      if (!result) return;
    }

    const newFiles = await uploadFiles(images_inputs);

    const data = {};

    generalConfig.forEach((configKey) => {
      data[configKey] = appconfig[configKey];
    });

    newFiles.forEach((file) => {
      data[file.key] = file.fileName;
    });

    await saveAppConfigPages();
    await updateAppConfig(data);
    setAppConfigProperty("loading", false);
  };

  return {
    saveAppConfig,
  };
};

export default useCrudAppConfig;
