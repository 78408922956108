import React, { useContext } from "react";
import { ClassReservationPenaltysContext } from "../../context/ClassReservationPenaltysContext";
import { getValue } from "../../utils";
import moment from "moment";

const PenaltyForm = ({ customer_id, handleCancel, handleCallback }) => {
  const {
    class_reservation_penalty,
    saveClassReservationPenalty,
    setPropertyClassReservationPenalty,
  } = useContext(ClassReservationPenaltysContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveClassReservationPenalty(
      { ...class_reservation_penalty, customer_id },
      handleCallback
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Fecha de Inicio</label>
      <input
        type="date"
        className="form-control mb-3"
        value={getValue(class_reservation_penalty, "start_date", "date")}
        onChange={(e) =>
          setPropertyClassReservationPenalty(
            "start_date",
            moment(e.target.value).format("YYYY-MM-DD 00:00:00")
          )
        }
      />
      <label>Fecha de Fin</label>
      <input
        type="date"
        className="form-control mb-3"
        value={getValue(class_reservation_penalty, "end_date", "date")}
        onChange={(e) =>
          setPropertyClassReservationPenalty(
            "end_date",
            moment(e.target.value).format("YYYY-MM-DD 23:59:59")
          )
        }
      />
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn w-100 text-muted"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn w-100 btn-primary">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default PenaltyForm;
