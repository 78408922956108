import { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import ReactSwitch from "react-switch";
import { getValue } from "../../utils";

const SingleClassesSettings = () => {
  const appconfig = useContext(AppConfigContext);
  const {
    max_guests_per_class,
    setAppConfigProperty,
    cancel_timeframe_unit,
    cancel_timeframe_value,
    reservation_timeframe_hours,
    reservation_timeframe_minutes,
  } = appconfig;

  return (
    <div className="row w-100">
      <h2 className="h3 mb-3 pb-3 border-bottom">Reservaciones</h2>

      <div className="col-12 col-md-6 mb-3">
        <h5 className="mb-1">Tolerancia Mínima Para Reservar</h5>
        <p>
          Elige si deseas <span className="bold">detener las reservas</span>{" "}
          algunos minutos u horas antes.
        </p>
        <div className="row">
          <div className="col-6">
            <label className="small">Horas</label>
            <input
              type="number"
              className="form-control"
              value={reservation_timeframe_hours}
              onChange={(event) =>
                setAppConfigProperty(
                  "reservation_timeframe_hours",
                  event.target.value
                )
              }
              max={23}
              min={0}
            />
          </div>
          <div className="col-6">
            <label className="small">Minutos (0 - 59)</label>
            <input
              type="number"
              className="form-control"
              value={reservation_timeframe_minutes}
              max={59}
              min={0}
              onChange={(event) =>
                setAppConfigProperty(
                  "reservation_timeframe_minutes",
                  event.target.value
                )
              }
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <h5 className="mb-1">Permitir Invitados</h5>
        <p>
          Elige si tus clientes pueden reservar{" "}
          <span className="bold">más de 1 lugar</span> por clase indicando el
          nombre de su invitado.
        </p>
        <div className="row">
          <div className="col-4">
            <label className="small d-block mb-1">Activado</label>
            <ReactSwitch
              checked={getValue(appconfig, "allow_class_guests", "boolean")}
              onChange={(checked) =>
                setAppConfigProperty("allow_class_guests", checked ? "1" : "0")
              }
            />
          </div>
          <div className="col-8">
            <label className="small">
              Máximo de Invitados por Clase (0 = sin límite)
            </label>
            <input
              type="number"
              className="form-control"
              style={{ maxWidth: 100 }}
              value={max_guests_per_class}
              onChange={(e) =>
                setAppConfigProperty("max_guests_per_class", e.target.value)
              }
            />
          </div>
        </div>
      </div>

      <h2 className="h3 mb-3 pb-3 border-bottom">Cancelaciones</h2>

      <div className="col-12 col-md-6 mb-3">
        <h5 className="mb-1">Tiempo de Tolerancia para Cancelación</h5>
        <p>
          El mínimo de tiempo que se necesita{" "}
          <span className="bold">previo a la clase</span> para que el cliente
          pueda cancelar su clase{" "}
          <span className="bold">sin perder su crédito</span> de clase. Elige{" "}
          <span className="bold">"0"</span> si no quieres penalizar
          cancelaciones.
        </p>
        <div className="d-flex">
          <input
            type={"number"}
            className="form-control me-2"
            style={{
              maxWidth: "max-content",
            }}
            value={cancel_timeframe_value}
            onChange={(event) =>
              setAppConfigProperty("cancel_timeframe_value", event.target.value)
            }
          />

          <select
            className="form-control"
            style={{
              maxWidth: "max-content",
            }}
            value={cancel_timeframe_unit}
            onChange={(event) =>
              setAppConfigProperty("cancel_timeframe_unit", event.target.value)
            }
          >
            <option value={"days"}>Días</option>
            <option value={"hours"}>Horas</option>
            <option value={"minutes"}>Minutos</option>
          </select>
        </div>
      </div>
      <div className="col-12 col-md-6 mb-3">
        <h5 className="mb-1">Permitir Cancelar Fuera de Tolerancia</h5>
        <p>
          <span className="bold">"Encendido"</span> significa que el cliente
          puede cancelar de todos modos a pesar de{" "}
          <span className="text-danger bold">perder su crédito</span>.{" "}
          <span className="bold">"Apagado"</span> significa que el{" "}
          <span className="text-danger bold">
            botón de "cancelar" se oculta
          </span>{" "}
          si ya pasó el tiempo de tolerancia.
        </p>
        <ReactSwitch
          checked={getValue(
            appconfig,
            "allow_cancel_after_treshold",
            "boolean"
          )}
          onChange={(checked) => {
            setAppConfigProperty(
              "allow_cancel_after_treshold",
              checked ? "1" : "0"
            );
          }}
        />
      </div>

      <h2 className="h3 mb-3 pb-3 border-bottom">Reservas Manuales</h2>

      <div className="col-12 col-md-6 mb-3">
        <h5 className="mb-1">Permitir Más Invitados del Límite</h5>
        <p>
          <span className="bold">"Encendido"</span> significa que cualquier{" "}
          <span className="text-primary bold">administrador</span> puede agregar
          a un mismo cliente a una clase{" "}
          <span className="text-danger bold">aunque</span> rebase el{" "}
          <span className="text-danger bold">límite de invitados</span>{" "}
          permitido.
        </p>
        <ReactSwitch
          checked={getValue(
            appconfig,
            "allow_manual_book_over_max_guests",
            "boolean"
          )}
          onChange={(checked) => {
            setAppConfigProperty(
              "allow_manual_book_over_max_guests",
              checked ? "1" : "0"
            );
          }}
        />
      </div>

      <div className="col-12 col-md-6 mb-3">
        <h5 className="mb-1">Permitir Reservas después de Tolerancia Mínima</h5>
        <p>
          <span className="bold">"Encendido"</span> significa que cualquier{" "}
          <span className="text-primary bold">administrador</span> puede agregar
          clientes a una clase <span className="text-danger bold">después</span>{" "}
          de la <span className="text-danger bold">tolerancia mínima</span>.
        </p>
        <ReactSwitch
          checked={getValue(
            appconfig,
            "allow_manual_book_after_treshold",
            "boolean"
          )}
          onChange={(checked) => {
            setAppConfigProperty(
              "allow_manual_book_after_treshold",
              checked ? "1" : "0"
            );
          }}
        />
      </div>

      <div className="col-12 col-md-6 mb-3">
        <h5 className="mb-1">Permitir Reservas Manuales en Clases Llenas</h5>
        <p>
          <span className="bold">"Encendido"</span> significa que cualquier{" "}
          <span className="text-primary bold">administrador</span> puede agregar
          clientes a una clase <span className="text-danger bold">llena</span>{" "}
          aunque{" "}
          <span className="text-danger bold">supere su capacidad máxima</span>.
        </p>
        <ReactSwitch
          checked={getValue(
            appconfig,
            "allow_manual_book_full_class",
            "boolean"
          )}
          onChange={(checked) => {
            setAppConfigProperty(
              "allow_manual_book_full_class",
              checked ? "1" : "0"
            );
          }}
        />
      </div>

      {getValue(appconfig, "allow_manual_book_full_class", "boolean") && (
        <div className="col-12 col-md-6 mb-3">
          <h5 className="mb-1">
            Mostrar Mensaje de Precaución para Clases Llenas
          </h5>
          <p>
            <span className="bold">"Encendido"</span> significa que cuando un
            usuario <span className="text-primary bold">administrador</span>{" "}
            intente agregar clientes a una clase{" "}
            <span className="text-danger bold">llena</span> le mostrará un
            diálogo para <span className="text-danger bold">confirmar</span> la
            acción. .
          </p>
          <ReactSwitch
            checked={getValue(
              appconfig,
              "warn_manual_book_full_class",
              "boolean"
            )}
            onChange={(checked) => {
              setAppConfigProperty(
                "warn_manual_book_full_class",
                checked ? "1" : "0"
              );
            }}
          />
        </div>
      )}

      <h2 className="h3 mb-3 pb-3 border-bottom">Membresías Ilimitadas</h2>

      <h3 className="h5 mb-3">Penalizaciones</h3>

      <div className="col-12 col-md-6 mb-3">
        <h5 className="mb-1">Penalizar Clientes con Membresía Ilimitada</h5>
        <p>
          <span className="bold">"Encendido"</span> significa que cuando un
          cliente <span className="text-primary bold">falta</span> a sus clases{" "}
          <span className="text-danger bold">tendrá</span> tendrá una
          penalización que le{" "}
          <span className="text-danger bold">cancelará</span> sus próximas
          reservas y <span className="text-danger bold">no podrá</span> reservar
          nuevas clases hasta que se levante su penalización.
        </p>
        <ReactSwitch
          checked={getValue(
            appconfig,
            "unlimited_membership_penalties_enabled",
            "boolean"
          )}
          onChange={(checked) => {
            setAppConfigProperty(
              "unlimited_membership_penalties_enabled",
              checked ? "1" : "0"
            );
          }}
        />
      </div>

      {getValue(
        appconfig,
        "unlimited_membership_penalties_enabled",
        "boolean"
      ) && (
        <>
          <div className="col-12 col-md-6 mb-3">
            <h5 className="mb-1">
              Permitir Reservas Manuales de Clientes Penalizados
            </h5>
            <p>
              <span className="bold">"Encendido"</span> significa que un
              administrador <span className="text-primary bold">si</span> puede
              agregar al cliente a una clase{" "}
              <span className="text-primary bold">manualmente.</span>. "Apagado"
              significa que el cliente{" "}
              <span className="text-danger bold">no</span> podrá ser agregado a
              clases.
            </p>
            <ReactSwitch
              checked={getValue(
                appconfig,
                "allow_manual_book_on_penalty",
                "boolean"
              )}
              onChange={(checked) => {
                setAppConfigProperty(
                  "allow_manual_book_on_penalty",
                  checked ? "1" : "0"
                );
              }}
            />
          </div>

          <div className="col-12 col-md-6 mb-3">
            <h5 className="mb-1">Cantidad de Faltas para Penalización</h5>
            <p>
              Define la cantidad de faltas{" "}
              <span className="text-primary bold">seguidas</span> para generar
              una penalización.
            </p>
            <div className="row align-items-center">
              <div className="col-4">
                <input
                  type="number"
                  className="form-control"
                  value={getValue(
                    appconfig,
                    "unlimited_max_no_shows_for_penalty",
                    "number"
                  )}
                  onChange={(e) =>
                    setAppConfigProperty(
                      "unlimited_max_no_shows_for_penalty",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="col-8">faltas seguidas</div>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-4">
            <h5 className="mb-1">Cantidad de Días de Penalización</h5>
            <p>
              Define la cantidad de{" "}
              <span className="text-primary bold">días</span> que el cliente
              tendrá activa su penalización.
            </p>
            <div className="row align-items-center">
              <div className="col-4">
                <input
                  type="number"
                  className="form-control"
                  value={getValue(
                    appconfig,
                    "unlimited_default_penalty_days",
                    "number"
                  )}
                  onChange={(e) =>
                    setAppConfigProperty(
                      "unlimited_default_penalty_days",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="col-8">faltas seguidas</div>
            </div>
          </div>
        </>
      )}

      <div className="col-12">
        <h3 className="h5 mb-3">Límite de Reservas</h3>
      </div>

      <div className="col-12 col-md-6 mb-4">
        <h5 className="mb-1">Máximo de Reservas Diarias</h5>
        <p>
          Limitar la cantidad de reservas{" "}
          <span className="text-primary bold">diarias</span> que puede hacer un
          cliente con membresía ilimitada. Dejar vacío para no limitar.
        </p>
        <div className="row align-items-center">
          <div className="col-4">
            <input
              type="number"
              className="form-control"
              value={getValue(
                appconfig,
                "unlimited_max_daily_reservations",
                "number"
              )}
              onChange={(e) =>
                setAppConfigProperty(
                  "unlimited_max_daily_reservations",
                  e.target.value
                )
              }
            />
          </div>
          <div className="col-8">reservas diarias</div>
        </div>
      </div>

      <div className="col-12 col-md-6 mb-4">
        <h5 className="mb-1">Máximo de Reservas en Horarios AM / PM</h5>
        <p>
          Limitar la cantidad de reservas{" "}
          <span className="text-primary bold">diarias</span> que puede hacer un
          cliente con membresía ilimitada en horarios{" "}
          <span className="text-primary bold">AM / PM</span>. Dejar vacío para
          no limitar.
        </p>
        <div className="row align-items-center">
          <div className="col-6">
            <div className="row align-items-center">
              <div className="col-6">
                <input
                  type="number"
                  className="form-control"
                  value={getValue(
                    appconfig,
                    "unlimited_max_am_reservations",
                    "number"
                  )}
                  onChange={(e) =>
                    setAppConfigProperty(
                      "unlimited_max_am_reservations",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="col-6">
                <label>reservas AM</label>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row align-items-center">
              <div className="col-6">
                <input
                  type="number"
                  className="form-control"
                  value={getValue(
                    appconfig,
                    "unlimited_max_pm_reservations",
                    "number"
                  )}
                  onChange={(e) =>
                    setAppConfigProperty(
                      "unlimited_max_pm_reservations",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="col-6">
                <label>reservas PM</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleClassesSettings;
